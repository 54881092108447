<template>
	<div class="shopSearch">
	<!-- 	<div class="search">
			输入搜索
			
		</div> -->
				<div class="addList" @click="addList1()" v-if='flag==4'>添加</div>
			<div class="addList" @click="addList2()" v-if='flag==5'>添加</div>
			<div class="addList" @click="addList3()" v-if='flag==6'>添加</div>
		<div class="searchList">
			<!-- <div class="searchTex">数据列表</div> -->
	
		</div>

		<Modal v-model="modal1" title="添加行业信息" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate" :model="formValidate" :label-width="80">
				<FormItem label="行业名称" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入行业名称"></Input>
				</FormItem>
				<!-- <FormItem label="排序" prop="ranking">
					<Input v-model="formValidate.ranking" placeholder="请输入排序"></Input>
				</FormItem> -->
			</Form>
		</Modal>

		<Modal v-model="modal2" title="添加主营类目" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate1" :model="formValidate1" :label-width="80">
				<FormItem label="类目名称" prop="name">
					<Input v-model="formValidate1.name" placeholder="请输入类目名称"></Input>
				</FormItem>
			</Form>
			<el-form ref="form" :model="formValidate1" label-width="80px">
				<el-form-item label="活动区域">
					<el-select v-model="formValidate1.ranking" placeholder="请选择活动区域">
						<el-option :label="item.i_name" :value="item.i_id" v-for="item,index in datas" :key='index'></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: 'shopSearch',
		data() {
			return {
				initial:['A','B','C','D','E','F','G','H','I',"J",'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
				formValidate: {
					name: '',
					// ranking: ''
				},
				formValidate1: {
					name: '',
					ranking: ''
				},
				formValidate3: {
					name: '',
					initial: '',
					ranking: ''
				},
				modal1: false,
				modal2: false,
				value3: false,
				styles: {
					height: 'calc(100% - 55px)',
					overflow: 'auto',
					paddingBottom: '53px',
					position: 'static'
				},
				formData: {
					name: '',
					url: '',
					owner: '',
					type: '',
					approver: '',
					date: '',
					desc: ''
				},
				datas: null
			}
		},
		props: {
			flag: {
				type: Number,
			}
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.selIndustryList({
				token
			}).then(res => {
				if (res.code == 0) {
					console.log(res);
					this.datas = res.data
				}
			})
		},
		components: {

		},
		methods: {
			addList3() {
				this.$router.push({path:'/addbrand'});
			},
			addList1() {
				this.modal1 = true;
			},
			addList2() {
				this.modal2 = true;
			},
			ok() {
				let token = this.$storage.getLocal('token');
				if (this.flag == 4) {
					this.$request.addIndustry({
						token,
						name: this.formValidate.name,
						ranking: 100
					}).then(res => {
						if (res.code == 0) {
							this.$Message.info('添加成功');
							this.$emit("add", 'ok');
							// this.$refs['formValidate'].resetFields();
						} else {
							this.$Message.info('添加失败:' + res.msg);
						}
					})
				} else if (this.flag == 5) {
					this.$request.addCategory({
						token,
						id: this.formValidate1.ranking,
						name: this.formValidate1.name,
						// this.$refs['formValidate1'].resetFields();
					}).then(res => {
						if (res.code == 0) {
							this.$Message.info('添加成功');
							this.$emit("add", 'ok');
						} else {
							this.$Message.info('添加失败:' + res.msg);
						}
					})
				}

			},
			cancel() {
				this.$Message.info('Clicked cancel');
			}
		}
	}
</script>


<style scoped="scoped">
	.shopSearch {
		width: 100%;
	}
	.search{
		border: 1px solid black;
	}

	.searchList {
		width: 80%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.addList {
		width: 100px;
		height: 40px;
		background-color: red;
		/* border: 1px solid #2C3E50; */
		line-height: 40px;
		/* border-radius: ; */
		color: white;
		cursor: pointer;
		border-radius: 10px;
		margin-top: 10px;
	}

	.demo-drawer-footer {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		border-top: 1px solid #e8e8e8;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>
