var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout"},[_c('Sider',{style:({
        position: 'fixed',
        height: '93.5vh',
        left: 0,
        overflow: 'auto',
      })},[_c('Menu',{attrs:{"active-name":_vm.top1,"theme":"dark","width":"auto","open-names":_vm.top2}},_vm._l((_vm.selList),function(item,index){return _c('Submenu',{key:index,attrs:{"name":item.node_val}},[_c('template',{slot:"title"},[_c('img',{staticClass:"img1",attrs:{"src":_vm.imgU + item.img[1]}}),_vm._v(" "+_vm._s(item.node_name)+" ")]),_vm._l((item.son),function(items,index){return _c('Menu-item',{attrs:{"name":items.node_val},nativeOn:{"click":function($event){return _vm.skip(items.node_url, items.node_val, items.node_name)}}},[_c('b',[_vm._v(_vm._s(items.node_name))])])})],2)}),1)],1),_c('Header',{style:({
        background: '#fff',
        boxShadow: '0 2px 3px 2px rgba(0,0,0,.1)',
        padding: '0 0',
      })},[_c('div',{staticClass:"menu"},[_c('div',{ref:"titleUser",staticClass:"titleUser"},[_c('div',{staticClass:"login"},[_c('img',{attrs:{"src":require("../../../public/xmwp.png")}})]),_c('div',{staticClass:"titleO"},[_c('div',{staticClass:"title_left title"},[_c('div',{staticClass:"loginText"},[_vm._v("行长甄选后台管理系统（南宁分行）")])]),_c('div',{staticClass:"title_right title"},[_c('div',{staticClass:"user item"},[_c('div',{staticStyle:{"line-height":"88px"}})]),_c('div',{staticClass:"admin item"},[_vm._v(_vm._s(_vm.login_name))]),_c('div',{staticClass:"index item",attrs:{"title":"首页"},on:{"click":function($event){return _vm.skip('index')}}},[_c('i',{staticClass:"el-icon-s-home"})]),_c('div',{staticClass:"exit item",attrs:{"title":"注销登录"},on:{"click":function($event){return _vm.loginout()}}},[_c('i',{staticClass:"el-icon-switch-button"})])])])])])]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }