<template>
  <div class="layout">
    <Sider
      :style="{
        position: 'fixed',
        height: '93.5vh',
        left: 0,
        overflow: 'auto',
      }"
    >
      <Menu :active-name="top1" theme="dark" width="auto" :open-names="top2">
        <Submenu
          :name="item.node_val"
          v-for="(item, index) in selList"
          :key="index"
        >
          <template slot="title">
            <!-- <Icon type="ios-navigate"></Icon> -->
            <img class="img1" :src="imgU + item.img[1]" />
            {{ item.node_name }}
          </template>
          <Menu-item
            v-for="(items, index) in item.son"
            :name="items.node_val"
            @click.native="
              skip(items.node_url, items.node_val, items.node_name)
            "
            ><b>{{ items.node_name }}</b></Menu-item
          >
        </Submenu>

        <!-- 		<Submenu name="1">
					<template slot="title">
						<Icon type="ios-navigate"></Icon>
						Item 1
					</template>
					<Menu-item name="1-1" @click.native="skip('index')"><b >首页</b></Menu-item>
					<Menu-item name="1-2" @click.native="skip('maintain')"><b >成员列表</b></Menu-item>
					<Menu-item name="1-3">Option 3</Menu-item>
				</Submenu> -->
        <!-- 		<Submenu name="4">
					<template slot="title">
						<Icon type="ios-analytics"></Icon>
						商品
					</template>
					<Menu-item name="4-1" @click.native="skip('commodityList')"><b>商品列表</b></Menu-item>
					<Menu-item name="4-2" @click.native="skip('addCommodity')"><b >添加商品</b></Menu-item>
					<Menu-item name="4-3" @click.native="skip('commodityAudit')"><b >商品审核</b></Menu-item>
					<Menu-item name="4-4" @click.native="skip('commodityRecycle')"><b >商品回收站</b></Menu-item>
					
					<Menu-item name="4-6" @click.native="skip('commodityComment')"><b >商品评价</b></Menu-item>
					<Menu-item name="4-7" @click.native="skip('commodityClassify')"><b >商品分类</b></Menu-item>
					<Menu-item name="4-8" @click.native="skip('commodityType')"><b >商品类型</b></Menu-item>
					<Menu-item name="4-9" @click.native="skip('commoditySign')"><b >商品标记</b></Menu-item>
					<Menu-item name="4-10" @click.native="skip('commodityMeasure')"><b >商品计量单位</b></Menu-item>
					
				</Submenu> -->
        <!-- 	<Submenu name="2">
					<template slot="title">
						<Icon type="ios-keypad"></Icon>
						店铺
					</template>
					<Menu-item name="2-1" @click.native="skip('addList')"><b >店铺列表</b></Menu-item>
					<Menu-item name="2-2" @click.native="skip('addshop')"><b>添加店铺</b></Menu-item>
					<Menu-item name="2-3" @click.native="skip('industry')"><b>行业管理</b></Menu-item>
					<Menu-item name="2-4" @click.native="skip('categories')"><b>主营类目</b></Menu-item>
					<Menu-item name="2-5" @click.native="skip('brand')"><b>品牌管理</b></Menu-item>
				</Submenu>
				<Submenu name="5">
					<template slot="title">
						<Icon type="ios-analytics"></Icon>
						订单
					</template>
					<Menu-item name="5-1" @click.native="skip('orderList')"><b>订单列表</b></Menu-item>
					<Menu-item name="5-2" @click.native="skip('affirmGoods')"><b>确认收货</b></Menu-item>
					<Menu-item name="5-3" @click.native="skip('arriveRemind')"><b>到货提醒</b></Menu-item>
					<Menu-item name="5-4" @click.native="skip('orderSetting')"><b>订单设置</b></Menu-item>
					<Menu-item name="5-5" @click.native="skip('salesReturn')"><b>退货申请处理</b></Menu-item>
					<Menu-item name="5-6" @click.native="skip('reimburse')"><b>退款申请处理</b></Menu-item>
					<Menu-item name="5-7" @click.native="skip('causeReturn')"><b>退货原因设置</b></Menu-item>
					<Menu-item name="5-8" @click.native="skip('expressWaybill')"><b>快递单模板</b></Menu-item>
					<Menu-item name="5-9" @click.native="skip('definedPrint')"><b>自定义打印</b></Menu-item>
					<Menu-item name="5-10" @click.native="skip('deliverPoint')"><b>发货点信息管理</b></Menu-item>
				</Submenu>
				<Submenu name="6">
					<template slot="title">
						<Icon type="ios-analytics"></Icon>
						会员
					</template>
					<Menu-item name="6-1" @click.native="skip('member')"><b>会员列表</b></Menu-item>
					<!-- <Menu-item name="6-2"><b @click="skip('affirmGoods')">购买力筛选</b></Menu-item> -->
        <!-- <Menu-item name="6-3" @click.native="skip('Manlabel')"><b>标签管理</b></Menu-item>
					<Menu-item name="6-4" @click.native="skip('manrank')"><b>会员等级设置</b></Menu-item>
					<Menu-item name="6-5" @click.native="skip('salesReturn')"><b>成长值及积分查询</b></Menu-item>
					<Menu-item name="6-6" @click.native="skip('reimburse')"><b >任务奖励设置</b></Menu-item>
					<Menu-item name="6-7" @click.native="skip('causeReturn')"><b>更多规则设置</b></Menu-item>
				</Submenu>
					<Submenu name="8">
					<template slot="title">
						<Icon type="ios-analytics"></Icon>
						库存
					</template>
					<Menu-item name="8-1" @click.native="skip('shopset')"><b>商品入库</b></Menu-item>
					<Menu-item name="8-2" @click.native="skip('shopget')"><b>商品出库</b></Menu-item>
				
				</Submenu>
				<Submenu name="9">
					<template slot="title">
						<Icon type="ios-analytics"></Icon>
						分销
					</template>
					<Menu-item name="9-1" @click.native="skip('setretail')"><b>分销基础设置</b></Menu-item>
					<Menu-item name="9-2" @click.native="skip('retailist')"><b>分销商列表</b></Menu-item>
					<Menu-item name="9-3" @click.native="skip('retailrank')"><b>分销等级</b></Menu-item>
				
				</Submenu>
			
				 <Submenu name="7">
				          <template slot="title">
				            <Icon type="ios-analytics"></Icon>
				            促销
				          </template>
				          <Menu-item name="7-1" @click.native="skip('activityList')"
				            ><b >秒杀活动列表</b></Menu-item
				          >
				          <Menu-item name="7-2" @click.native="skip('addCouponInfo')"
				            ><b>添加优惠券</b></Menu-item
				          >
				          <Menu-item name="7-3" @click.native="skip('couponList')"
				            ><b>优惠券列表</b></Menu-item
				          >
				        </Submenu>
				        <Submenu name="10">
				          <template slot="title">
				            <Icon type="ios-analytics"></Icon>
				            运营
				          </template>
				          <Menu-item name="10-1" @click.native="skip('advertisingList')"
				            ><b>广告列表</b></Menu-item
				          >
				          <Menu-item name="10-2" @click.native="skip('addAdvertising')"
				            ><b>添加广告</b></Menu-item
				          >
				        </Submenu>
				
				        <Submenu name="11">
				          <template slot="title">
				            <Icon type="ios-analytics"></Icon>
				            设置
				          </template>
				          <Menu-item name="11-1" @click.native="skip('latformInfo')"
				            ><b>平台信息</b></Menu-item
				          >
				          <Menu-item name="11-2" @click.native="skip('freight')"
				            ><b>运费模板</b></Menu-item
				          >
				          <Menu-item name="11-3" @click.native="skip('logistics')"
				            ><b>物流公司</b></Menu-item
				          >
				        </Submenu> -->
      </Menu>
    </Sider>
    <Header
      :style="{
        background: '#fff',
        boxShadow: '0 2px 3px 2px rgba(0,0,0,.1)',
        padding: '0 0',
      }"
    >
      <div class="menu">
        <div class="titleUser" ref="titleUser">
          <div class="login">
            <img src="../../../public/xmwp.png" />
          </div>
          <div class="titleO">
            <div class="title_left title">
              <div class="loginText">行长甄选后台管理系统（南宁分行）</div>
            </div>
            <div class="title_right title">
              <div class="user item">
                <div class="" style="line-height: 88px">
                  <!-- <el-avatar src="<https></https>://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar> -->
                </div>
              </div>

              <div class="admin item">{{ login_name }}</div>
              <!-- 首页 -->
              <div class="index item" title="首页" @click="skip('index')">
                <i class="el-icon-s-home"></i>
              </div>
              <!-- 菜单 -->
              <!-- <el-dropdown>
								<div class="list item" title="菜单导航"><i class="el-icon-s-operation"></i></div>
								<el-dropdown-menu slot="dropdown">
									<div class="menus">
										<div class="menuCon">
											<div class="menuTit">
												<div class="titleUse">常用菜单</div>
												<div class="management">菜单管理</div>
											</div>
											<div class="menuNei">
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
											</div>
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown> -->
              <!-- 订单 -->
              <!-- <el-dropdown>
								<div class="message item" title="提示消息"><i class="el-icon-message-solid"></i>
									<div class="quan">1</div>
								</div> -->
              <!-- <el-dropdown-menu slot="dropdown">
									<div class="messages">
										<div class="messageCla">
											<div class="mesClaTit">
												订单提示
											</div>
											<div class="mesList">
												<div class="conNam">123</div>
												<div class="num">(10)</div>
											</div>
											<div class="mesList">
												<div class="conNam">234</div>
												<div class="num">(10)</div>
											</div>
											<div class="mesList">
												<div class="conNam">345</div>
												<div class="num">(10)</div>
											</div>
											<div class="mesList">
												<div class="conNam">456</div>
												<div class="num">(10)</div>	
											</div>
										</div>
									</div>
								</el-dropdown-menu>	 -->
              <!-- </el-dropdown> -->
              <!-- 主题管理 -->
              <!-- 		<el-dropdown>
								<div class="bkcolor item el-dropdown-link" title="更换主题颜色"><i class="el-icon-brush"></i></div>
								<el-dropdown-menu slot="dropdown">
									
									<div class="colorStyle">
										<div class='color' @click="styleColor('#1ABC9C')" style="background-color: #1ABC9C;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#3498DB')" style="background-color: #3498DB;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#ED6E4D')" style="background-color: #ED6E4D;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#FABB3D')" style="background-color: #FABB3D;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#AA7AB3')" style="background-color: #AA7AB3;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#9AD0B9')" style="background-color: #9AD0B9;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#EA94BE')" style="background-color: #EA94BE;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#D9001B')" style="background-color: #D9001B;">
											点击换肤
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown> -->

              <!-- 退出登录 -->
              <div class="exit item" title="注销登录" @click="loginout()">
                <i class="el-icon-switch-button"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Header>
    <!-- <Layout :style="{marginLeft: '200px'}"> -->

    <!-- <Content :style="{padding: '0 16px 16px'}"> -->

    <!-- 	<Breadcrumb :style="{margin: '16px 0'}">
					
				</Breadcrumb> -->
    <slot> </slot>
    <!-- </Content> -->

    <!-- </Layout> -->
  </div>
</template>

<script>
export default {
  name: "frame",
  data() {
    return {
      login_name: "",
      selList: [],
      imgU: "",
      txt: "",
    };
  },
  created() {},
  props: {
    top1: {
      type: String,
    },
    top2: {
      type: Array,
    },
  },
  created() {
    let imgU = this.$request.readimgUrl();
    this.imgU = imgU;
    this.$nextTick(() => {
      this.$refs.titleUser.style.backgroundColor =
        this.$storage.getLocal("color");
    });
    let sel = this.$storage.getLocal("selList");
    this.selList = sel;
    this.login_name = this.$storage.getLocal("login_name");

    // this.$request.selRoleList({token}).then(res=>{
    //  if(res.code==0)
    // 			{
    // 			this.selList=res.data

    // 			}

    // })
  },

  methods: {
    btntext(e) {},
    shuai(e) {},
    skip(url, val, txt) {
      this.txt = txt;
      this.$router.push(
        {
          path: "/" + url,
        },
        function () {},
        function () {}
      );
    },
    styleColor(obj) {
      this.$storage.setLocal(obj, "color");
      this.$refs.titleUser.style.backgroundColor =
        this.$storage.getLocal("color");
    },
    loginout() {
      let token = this.$storage.getLocal("token");
      this.$request
        .logout({
          token,
        })
        .then((res) => {
          if (res.code == 0) {
            localStorage.clear();
            this.$router.push(
              {
                path: "/login",
              },
              function () {},
              function () {}
            );
          }
        });
    },
  },
};
</script>


<style scoped="scoped">
b {
  width: 80px;
}
.ivu-layout-sider {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  flex: 0 0 200px;
  position: fixed;
  height: 93vh;
  top: 65px;
  left: 0px;
  overflow: auto;
}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.login img {
  width: 160px;
  height: 40px;
  /* border: 1px solid black; */
  margin-top: 13px;
}
.login {
  /* width: 13.1%; */
  width: 200px;
  /* border: 1px solid red; */
  background-color: red;
}

.menu {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

/* 推荐菜单 */
.menus {
  width: 400px;
  height: 300px;
}

.menuCon {
  width: 88%;
  margin: 0 auto;
}

.menuTit {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #8492a6;
  margin-top: 10px;
}

.menuNei {
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #333333;
  margin-top: 10px;
}

.menuList {
  width: 33%;
  height: 90px;
  text-align: center;
  line-height: 90px;
}

/* 信息 */
.messages {
  width: 400px;
  height: 300px;
  font-size: 15px;
}

.messageCla {
  width: 88%;
  margin: 0 auto;
}

.mesClaTit {
  color: #b4b4b4;
  height: 35px;
}

.mesList {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cbcbcb;
  height: 35px;
  line-height: 35px;
}

/* 主题颜色 */
.color {
  width: 24.5%;
  height: 80px;
  border: 1px solid #ffffff;
  background: #333333;
  text-align: center;
  line-height: 80px;
  font-size: 12px;
  color: #ffffff;
}

.colorStyle {
  width: 400px;
  height: 160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
}

.colorStyle {
  background-color: #ffffff;
}

/* top */
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.loginText {
  color: black;
  margin-left: 20px;
  font-size: 25px;
  font-weight: 800;
  line-height: 60px;
}

.titleUser {
  width: 100%;
  height: 64px;
  line-height: 64px;
  /* background-color: #D9001B; */
  display: flex;
}

.title {
  height: 100%;
}

.titleO {
  width: 87%;
  display: flex;
  background-color: white;
}

.title_left {
  width: 50%;
  display: flex;
  background-color: white;
}

.title_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.title .item {
  line-height: 60px;
  color: black;
  margin-right: 10px;
  /* font-size: 20px; */
}

.index,
.list,
.message,
.bkcolor,
.exit {
  font-size: 20px;
}

.admin {
  font-size: 20px;
}

.message {
  position: relative;
}

.message .quan {
  width: 15px;
  height: 15px;
  background-color: #ff5918;
  position: absolute;
  top: 15px;
  right: -5px;
  border-radius: 15px;
  text-align: center;
  line-height: 15px;
  font-size: 10px;
}
.img1 {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}
</style>
